import React, { Fragment, useState } from 'react';


function Navigation() {
    const [navbar, setNavbar] = useState(false)
    
    //menu responsive 
    let [click, setClick] = useState(false)
    
    
    let handleClick = () => setClick(!click)
    let closeMobileMenu = () => setClick(false)
    
    
    // //mettre la couleur au navbar au moment du scroll
    // let changeBackground = () => {
    //     if (window.scrollY >= 50 ) {
    //         setNavbar(true)
    //     } else {
    //         setNavbar(false)
    //     }
    // }
    // window.addEventListener('scroll', changeBackground)


    return (
        <Fragment>
            <nav >
                <div className={click ? "navbar-active" : 'navbar'}>
                    <img src="/img/logo.png" alt="logo" width={35}/>
                    <div>
                        <a href= "/" onClick={ closeMobileMenu} className="text-decoration-none navlink" > Home </a>
                        <a href= "/#about" onClick={closeMobileMenu} className="text-decoration-none navlink " activeclassname="nav-active"> A propos </a>
                        <a href= "/#skills" onClick={closeMobileMenu} className="text-decoration-none navlink "> Compétences </a>
                        <a href= "/#galerie" onClick={closeMobileMenu} className="text-decoration-none navlink "> Réalisations </a>
                        <a href= "/#contact" onClick={closeMobileMenu} className="text-decoration-none navlink bg-light text-dark rounded p-2"> Contact </a>
                    </div>
 
                </div>
            </nav>

        </Fragment>
    );


}

export default Navigation;