import React, { Fragment, useEffect, useState } from 'react';
import "aos/dist/aos.css";
import Aos from 'aos';

function Competence(props) {
    let [skills] = useState([
        {nom: "HTML", icon:'fa-brands fa-html5'},
        {nom: "CSS", icon:'fa-brands fa-css3-alt'},
        {nom: "Javascript", icon:'fa-brands fa-js'},
        {nom: "Sass", icon:'fa-brands fa-sass'} ,
        {nom: "Github", icon:'fa-brands fa-github'},
        {nom: "React", icon:'fa-brands fa-react'},      
        {nom: "Laravel", icon:'fa-brands fa-laravel'},
        {nom: "Java", icon:'fa-brands fa-java'} 
    ])

    let [designs] = useState([
        {nom: "Illustrator", icon:'fa-solid fa-bezier-curve'},
        {nom: "InDesign", icon:'fa-solid fa-file'},
        {nom: "Premiere Pro", icon:'fa-solid fa-video'},
        {nom: "Adobe XD", icon:'fa-brands fa-html5'},
    ])

    useEffect(()=> {
        Aos.init({duration: 2000});
    }, [])
    return (
        <Fragment>
            <section className="competence" id="skills">
            <div className="container py-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className=" titre text-center">{props.titre} </h1>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 col-sm-12 text-center" data-aos="fade-up">
                            <h3 className="titre_competence mb-2">Développement web</h3>
                            <div className=" box">
                                {skills.map((el,i)=>{
                                    return (
                                    <Fragment key={i}  >
                                        <span className='text-competence '><i className={el.icon}></i> {el.nom}</span>  
                                    </Fragment>
                                    )
                                })}
                        </div>
                        </div>
                        <div className="mt-5 col-md-12 col-sm-12 text-center" data-aos="fade-up">
                            <h3 className="titre_competence mb-2">Adobe Creative</h3>
                            <div className=" box  ">
                                {designs.map((el,i)=>{
                                    return (
                                    <Fragment key={i}>
                                        <span className='text-competence'><i className={el.icon}></i> {el.nom}</span>  
                                    </Fragment>
                                    )
                                })}
                            </div>

                        </div>


                    </div>
                </div>

            </section>
        </Fragment>
    );
}

export default Competence;