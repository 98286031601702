import React, { Fragment } from 'react';
import Banniere from './Banniere';
import Navigation from './Navigation';


function Header() {


    return (
        <Fragment>
                <header>
                <Navigation />
                <Banniere/>                

            </header>
        </Fragment>
    );
}

export default Header;