import React, { Fragment } from 'react';

function Footer() {
    return (
        <Fragment>
            <footer>
                <div className="text-center">
                    <span className = "copy font-weight-light">
                        &copy; All rights reserved  2023 | Made with <i className="fas fa-heart text-danger"></i> </span>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;