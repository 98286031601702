import React, { Fragment } from 'react';

function Contact(props) {
    return (
        <Fragment>
            <section id="contact">
                        <h1 className = "text-center titre">{props.titre}</h1>
                        <hr />
                        <p className='texte-contact'>Merci de visiter mon portfolio, et n'hésitez pas à me contacter si vous souhaitez en savoir plus sur mes compétences ou discuter d'opportunités de collaboration.</p>
                <div className="reseaux">
                    <a href="https://www.linkedin.com/in/jamila-hammani/"  target="_blank"  rel="noreferrer"><i className="icon fab fa-linkedin "></i></a>
                    <a href="https://github.com/JamilaaH"  target="_blank" rel="noreferrer"><i className="icon fab fa-github "></i></a>
                    <a href="https://www.instagram.com/jamila.hmn/"  target="_blank" rel="noreferrer"><i className="icon fab fa-instagram"></i></a>
                    <a href="mailto:jamila-h@outlook.fr"  target="_blank" rel="noreferrer"><i className="fas fa-envelope"></i></a>
                </div>
            </section>
        </Fragment>
    );
}

export default Contact  