import React, { Fragment } from 'react';
import Header from './components/Header';
import About from './components/About';
import Competences from './components/Competences';
import Galerie from './components/Galerie';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Fragment>
      <Header/>
      <About titre="A propos"/>
      <Competences titre="Compétences" />
      <Galerie titre="Réalisations" />
      <Contact titre="Get in touch" />
      <Footer/>
    </Fragment>

  );
}

export default App;
