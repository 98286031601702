import React, { Fragment } from 'react';



function Banniere() {
    return (
        <Fragment>
            <div className="banniere">
                        <div className="contenu text-center">
                            <p className="titre">Jamila Hammani</p>
                            <p className="soustitre">Développeuse Full Stack Web | <br></br> UI - UX Designer </p>
                        </div>                            
                    <div className="scroll-indicator">
                        <span className="scroll-indicator__text">
                            Scroll
                        </span>

                        <span className="scroll-indicator__bar" ></span>
                    </div>
            </div>

        </Fragment>
    );
}

export default Banniere;